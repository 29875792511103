export default [
    {
      path: '/dashboard',
      name: 'dashboard-mechtech',
      component: () => import('@/views/pages/dashboard/Dashboard.vue'),
    },
    {
        path: '/user-profile',
        name: 'mechtech-user-profile',
        component: () => import('@/views/pages/user-profile/UserProfile.vue'),
        meta: {
          pageTitle: 'User Profile',
          breadcrumb: [
            {
              text: 'User Profile',
              active: true,
            },
          ],
        },
    },
    {
      path: '/courses',
      name: 'courses',
      component: () => import('@/views/pages/software-course/List.vue'),
      meta: {
        pageTitle: 'Courses',
        breadcrumb: [
          {
            text: 'Course List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/courses/:slug',
      name: 'course-detail',
      component: () => import('@/views/pages/software-course/Detail.vue')
    },
    {
      path: '/courses/:slug/:subject',
      name: 'course-learning',
      component: () => import('@/views/pages/software-course/Learning.vue')
    },

    // LANDING
    {
      path: '/',
      name: 'landing-mechtech',
      component: () => import('@/views/pages/landing/Landing.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/subscription-class',
      name: 'landing-subscription-class',
      component: () => import('@/views/pages/software-course/Landing.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/about',
      name: 'about-mechtech',
      component: () => import('@/views/pages/about-us/AboutUs.vue'),
      meta: {
        layout: 'full',
      },
    },
  ]
  