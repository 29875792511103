import useJwt from '@core/auth/jwt/useJwt'
import axios from '@axios'

const instance = axios.create({
    baseURL: process.env.API_URL,
    timeout: 5000,
  });

const { jwt } = useJwt(instance, {})
export default jwt
